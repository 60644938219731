body{
  background-color: black;
  color: white;
  font-family: 'Hind Siliguri', sans-serif;
}

div {
  background-color: rgb(35, 35, 35);
}

a {
  color: royalblue;
}

#root {
  background-color: black;
}

.App {
  text-align: center;
}

.App-logo {
  height: 35vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content{
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1%;
  min-height: 93vh;
  background-color: black;
  
  height: 100%;
  display:flex;
  flex-direction: column;
  /* padding-bottom: 1px; */
}

.header {
  border-bottom-style: solid;
  border-color: rgb(97, 97, 97);
  border-bottom-width: 1px;
  padding: 1%;
  /* padding-color:rgb(35, 35, 35); */

  display: flex;
  flex-direction: row;
  /* background-color: rgb(27,27,27); */
  margin-bottom:1%;
  border-style:solid;
  border-width: 1px;
  border-color: rgb(97, 97, 97);
  border-radius: 20px;
}

#headerPic{
  width:170px;
  height:170px;
  margin-top:auto;
  margin-bottom: auto;
  
  /* min-width: 150px; */
  /* min-height: 150px; */
}

.name {
  font-size: 3rem;
  /* text-align: center; */
  padding-left: 2%;
  /* color: #C29833; */
  font-family: 'Titillium Web', sans-serif;
}

.shortBio {
  margin:1.5%;
  padding-bottom:5px;
  /* border-bottom-style: solid;
  border-bottom-width: 1px;
  border-top-style: solid;
  border-top-width: .5px;
  border-color: rgba(128, 128, 128, 0.199); */
}

.mainContainer{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  flex: 1 1 auto;
  margin-top:1%;
  border-style:solid;
  border-width: 1px;
  border-color: rgb(97, 97, 97);
  border-radius: 20px;
  /* height: 100%; */
}

.moveButton {
  opacity: .1;
  background-color: rgb(80, 80, 80);
  width: 4%;
  color: whitesmoke;
  /* text-align: center; */
  /* vertical-align: middle;
  line-height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  border-radius: 19px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* font-stretch: ultra-condensed; */
}

.moveButton:hover {
  opacity: 1;
  background-color: rgb(80, 80, 80);
  -webkit-transition: all .25s;
  -moz-transition: all .25s;

  /* transform: scale(1); */
  animation: pulse 1s 1;
}

/* .moveButton span{
  transform: scale(1);
	animation: pulse 2s infinite;
} */

@keyframes pulse {
	0% {
    font-size: 2.00rem;
  }

	50% { 
    font-size: 2.15rem;
  }
	100% {
    font-size: 2.00rem;
		/* transform: scale(0.95); */
	}
}


.tableOfContents{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 1.5rem;
  margin-top: 1%;
  margin-bottom: 1%;
}

.navLink{
  opacity: .4;
  color: #C29833;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: black;
}

.navLink:hover{
  opacity: .7;
  border-bottom-color: #C29833;
  -webkit-transition: all .2s;
  -moz-transition: all .2s;
  cursor:pointer;
}

#selectedPage{
  opacity: 1;
  border-bottom-color: #C29833;
}

.card{
  background-color: rgb(18,18,18);

  padding: 2%;
  margin: 1%;
  border-radius: 20px;
  border-style:solid;
  border-color: black;
  border-width: 1px;

  /* display: flex;
  align-items: flex-end; */
}

.card div{
  background-color: rgb(18,18,18);
}

.cardRow{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom:1%;
  /* flex-grow: 1; */
  /* width: 100%; */
}

.cardContainer{
  width:100%;
  max-width: 850px;
  display:flex;
  /* background-color: rgb(18,18,18); */
  /* max-width: 850px; */
  /* width: 100%; */
  font-size:1.25rem;
  flex-direction: column;
}

.cardTitle{
  font-size: 1.75rem;
  color: #515C61;
  font-weight: 600;
}

.cardDescription{
  margin-bottom:1%;
}

.cardList{
  /* margin-left:0px; */
  padding-inline-start: 20px;
}

.demoVideo{
  min-width:700px;
  min-height: 350px;
  margin-left: auto;
  margin-right: auto;
  /* float: center; */
}

.demoWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .bioRow{
  margin-top:10%;
} */

.bioRow>li{
  list-style-type: circle;
  padding-left: 27px;
  text-indent: -27px;
}

.bioLabel{
  font-weight: 700;
  color: grey;
  text-align: left;
  border-spacing: 5px;
  padding-right: 15px;
  padding-top: 4%;

  /* padding-bottom: 2%; */
  /* margin-left: 1px; */
}

.bioTable{
  margin-left:5%
}

.links{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 1.15rem;
}

.footer{
  margin-left:auto;
  margin-right:auto;
  /* opacity: .8; */
}

.collabLink{
  opacity:.8;
  font-size:1rem;
}


/* button {
  color: white;
  font-size: 2rem;
  font-stretch: condensed;
  
} */

@media screen and (max-width: 480px) {
  .header{
    display:flex;
    flex-direction: column;
    padding: 6px;
    /* border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px; */
  }

  .name{
    font-size:2.8rem;
  }

  #headerPic{
    margin-left: auto;
    margin-right: auto;
    width: 250px;
    height: 250px;
    
  }

  .demoVideo{
    /* min-width:none; */
    /* min-height: none; */
    min-width:300px;
    min-height: 150px;
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* float: center; */
  }

  .links{
    flex-direction: column;
    flex-wrap: wrap;
    height: 60px;
  }
  .links>* {
    flex: 1 1 25px;
  }

  .links>a {
    margin-left: auto;
  }

  .moveButton{
    display:none;
  }

  .cardContainer{
    border-radius: 20px;
  }

  .content{
    /* margin: none; */
    width:99%;
    margin-left:auto;
    margin-right:auto;
  }

  .cardRow{
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0px;
  }

  .cardTitle{
    margin-bottom:5px;
  }

 
/* 
  .bioRow>div{
    margin-bottom: 4px;
  } */
  
}
